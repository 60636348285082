import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { Helmet } from "react-helmet";
import PageHeader from "../components/page-header";
import { GridWrapper, Wrapper } from "../components/layout-components";
import ImageCover from "../components/image-cover";
import Box from "../components/john-motion/motion-box";
import { Headline } from "../components/type";
import { clampBuilder } from "../functions/util";
import { OutlineImageParallaxBox } from "../components/outline-image-parallax-box";
import AnimatedText from "../components/john-motion/motion-headline";
import SvgLoader from "../components/svg-loader";
import TextGroup from "../components/text-group";
import BannerClassy from "../components/banner-classy";
import PageHead from "../components/globals/page-head";

function AboutHeaderSection({ data }) {
  return (
    <>
      {data?.year && (
        <span className="h1" data-bkg-el>
          {Array.from(data.year).map((c, i) => (
            <AnimatedText
              key={i}
              text={c}
              transitionOverrides={{ delay: i * 0.1 }}
            />
          ))}
        </span>
      )}
      {data?.entry && (
        <Headline size="p-large" lineLength={2}>
          {data.entry}
        </Headline>
      )}
      {data?.text && (
        <Headline size="p-large" lineLength={2.25}>
          {data.text}
        </Headline>
      )}
      {data?.title && (
        <Headline size="h6" lineLength={null}>
          {data.title}
        </Headline>
      )}
      {data?.caption && (
        <>
          <SvgLoader icon="IconStar" />
          <Headline size="title-large" lineLength={null}>
            {data.caption}
          </Headline>
        </>
      )}
    </>
  );
}

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function PageTemplate({
  data: {
    prismicSpecificPage: {
      data: { title },
    },
    prismicAssets: {
      data: {
        about_cover,
        image_2020,
        image_1958_03,
        image_1958_02,
        image_1958_01,
        image_1930_oil_01,
        image_1930_oil_02,
      },
    },
    prismicHomepage: {
      data: { classy_cta_action, classy_cta_action_text, classy_cta_image },
    },
  },
}) {
  const sections = [
    {
      year: "1930",
      title: "A short history",
      content: [
        {
          text: "Oil derricks line the streets of 1930 and 40s Long Beach, pumping enough oil to put the surrounding area on the map as one of the largest oil fields in the United States.",
          caption: "Long Beach Oil Feilds\ncirca 1930",
        },
      ],
      images: [
        {
          key: "image_1930_oil_01",
          image: image_1930_oil_01,
          level: 1,
        },
        {
          key: "image_1930_oil_02",
          image: image_1930_oil_02,
          level: 1,
        },
      ],
    },
    {
      year: "1958",
      title: "The Beginning",
      content: [
        {
          text: "Out of the oil riches, The Petroleum Club is born. Designed by the architect John Richard Shelley, the iconic arched roofline motif makes the building unique and unmistakable.",
          caption: "The Innagural Ball\ncirca 1958",
        },
      ],
      images: [
        { key: "image_1958_03", image: image_1958_03, level: 1 },
        { key: "image_1958_02", image: image_1958_02, level: 1 },
        { key: "image_1958_01", image: image_1958_01, level: 2 },
      ],
    },
    {
      year: "2019",
      entry:
        "After 60+ years the Petroleum Club closed its doors to an unpromising future and impending demolition",
      title: "A new beginning",
      images: [{ key: "image_2020", image: image_2020, level: 1 }],
      content: [
        {
          year: "2020",
          entry:
            "After being restored to its former greatness, this charming mid-century modern is open to all.",
          caption: "The New operators\n\nJim Choura And Dan D’sa",
        },
      ],
    },
  ];

  return (
    <>
      <PageHead title="About" />
      <PageHeader title="Welcome to the club" />
      <ImageCover fluid={about_cover} />
      <Line hiddenStyles={{ x: 0, y: 0 }} />
      <GridWrapper>
        {sections.map(({ year, title, content, images, entry }, i) => (
          <AboutSection key={i} data-section={year}>
            <AboutHeader>
              <AboutHeaderSection data={{ year, entry, title }} />
            </AboutHeader>
            {images &&
              images.map(({ key, image, level }, i) => (
                <OutlineImageParallaxBox
                  key={i}
                  data-key={key}
                  fluid={image?.fluid}
                  parallaxAmount={level === 1 ? 4 : 10}
                />
              ))}
            {content && (
              <AboutContent>
                {content.map((data, i) => (
                  <AboutHeaderSection key={i} data={data} />
                ))}
              </AboutContent>
            )}
          </AboutSection>
        ))}
      </GridWrapper>
      <TextGroup
        data={{
          primary: {
            cta_title: "Let's get classy",
            cta_title_size: "h2",
            button_link: "/inquire",
            button_label: "Plan your private event",
          },
        }}
      />
      <BannerClassy
        classy_cta_image={classy_cta_image}
        classy_cta_action_text={classy_cta_action_text}
        classy_cta_action={classy_cta_action}
      />
    </>
  );
}

const AboutBlock = styled(Box)`
  margin: 3rem auto;

  [data-bkg-el] {
    grid-row-start: 1;
    position: absolute;
    z-index: -1;
    display: inline-flex;

    @media only screen and ${({ theme }) => theme.device.laptop} {
      transform: translateY(15%);
    }

    &.h1,
    & .h1 {
      font-size: ${clampBuilder(150 / 16, 600 / 16, 375, 2560)};
      color: ${({ theme }) => theme.colors.darkBlack} !important;

      span {
        padding: 0;
      }
    }
  }

  .title-large {
    white-space: break-spaces;
    margin-top: 2rem;
    line-height: 2;
  }
`;

const Line = styled(Box)`
  width: 3px;
  height: 8vw;
  min-height: 6rem;
  background-color: ${({ theme }) => theme.colors.golden};
  margin: 0 auto;
`;

const AboutContent = styled(AboutBlock)`
  p-large {
    padding-right: 10%;
  }
`;

const AboutHeader = styled(AboutBlock)`
  display: grid;
  grid-auto-flow: row;
  grid-column: 1 / 11;
  align-content: start;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;

  [data-bkg-el] {
    grid-row-start: 1;
    position: absolute;
    z-index: -1;
    display: inline-flex;

    @media only screen and ${({ theme }) => theme.device.laptop} {
      transform: translateY(15%);
    }

    &.h1,
    & .h1 {
      font-size: ${clampBuilder(150 / 16, 600 / 16, 375, 2560)};
      color: ${({ theme }) => theme.colors.darkBlack} !important;
import PageHead from '../components/globals/page-head';

      span {
        padding: 0;
      }
    }
  }
`;

const AboutSection = styled(Box)`
  display: grid;
  grid-template-columns: [start] repeat(6, 1fr) [end];
  grid-column-start: start;
  grid-column-end: end;
  grid-auto-flow: row;
  margin: ${clampBuilder(60 / 16, 150 / 16)} 8vw 0;
  z-index: -1;

  @media only screen and ${({ theme }) => theme.device.laptop} {
    grid-template-columns: [start] repeat(10, 1fr) [end];
    margin: 5vw 0;
  }

  .p-large {
    line-height: 180%;
    white-space: pre-line;
  }

  &[data-section="1930"] {
    height: 120vw;
    min-height: 56rem;
    grid-template-rows: [start] repeat(7, 1fr) [end];

    @media only screen and ${({ theme }) => theme.device.laptop} {
      height: 50vw;
      min-height: 56rem;
      grid-template-rows: [start] repeat(5, 1fr) [end];
    }

    [data-key="image_1930_oil_01"] {
      grid-row-start: 2;
      grid-row-end: 7;
      grid-column-start: 1;
      grid-column-end: 5;

      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 2;
        grid-row-end: 6;
        grid-column-start: 2;
        grid-column-end: 6;
      }
    }

    [data-key="image_1930_oil_02"] {
      grid-row-start: 5;
      grid-row-end: 8;
      grid-column-start: 4;
      grid-column-end: 7;

      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 9;
        grid-column-end: 11;
      }
    }

    ${AboutContent} {
      grid-row-start: 9;
      grid-column-start: start;
      grid-column-end: end;

      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 4;
        grid-row-end: 6;
        grid-column-start: 7;
        grid-column-end: 12;
      }
    }

    ${AboutHeader} {
      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 1;
      }
    }
  }

  &[data-section="1958"] {
    height: 150vmin;
    min-height: 68rem;
    grid-template-rows: [start] repeat(11, 1fr) [end];

    @media only screen and ${({ theme }) => theme.device.laptop} {
      height: 70vw;
      min-height: 70rem;
      grid-template-columns: [start] repeat(10, 1fr) [end];
      grid-template-rows: [start] repeat(7, 1fr) [end];
    }

    [data-key="image_1958_01"] {
      grid-row-start: 2;
      grid-row-end: 6;
      grid-column-start: 1;
      grid-column-end: 5;

      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 5;
        grid-row-end: 8;
        grid-column-start: 1;
        grid-column-end: 5;
      }
    }

    [data-key="image_1958_02"] {
      grid-row-start: 7;
      grid-row-end: 11;
      grid-column-start: 2;
      grid-column-end: 5;

      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 2;
        grid-row-end: 6;
        grid-column-start: 6;
        grid-column-end: 10;
      }
    }

    [data-key="image_1958_03"] {
      grid-row-start: 5;
      grid-row-end: 8;
      grid-column-start: 4;
      grid-column-end: 7;
      z-index: 2;

      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 5;
        grid-row-end: 7;
        grid-column-start: 9;
        grid-column-end: 11;
      }
    }

    ${AboutContent} {
      grid-row-start: 11;
      grid-column-start: start;
      grid-column-end: end;

      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 2;
        grid-row-end: 5;
        grid-column-start: 2;
        grid-column-end: 6;
      }
    }
  }

  &[data-section="2019"] {
    height: 220vw;
    min-height: 66rem;
    grid-template-rows: [start] repeat(3, 1fr) [end];

    @media only screen and ${({ theme }) => theme.device.laptop} {
      height: 90vw;
      min-height: 80rem;
      grid-template-columns: [start] repeat(10, 1fr) [end];
      grid-template-rows: [start] repeat(8, 1fr) [end];
    }

    ${AboutHeader} {
      grid-row-start: 1;
      grid-column-start: start;
      grid-column-end: end;

      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: start;
        grid-column-end: end;
      }
    }

    ${AboutContent} {
      grid-row-start: 3;
      grid-column-start: start;
      grid-column-end: end;
      z-index: -1;

      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 8;
        display: grid;
        align-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
        text-align: center;
        padding-top: 8vw;

        [data-bkg-el] {
          @media only screen and ${({ theme }) => theme.device.laptop} {
            transform: translateY(-15%);
          }
        }
      }
    }

    [data-key="image_2020"] {
      grid-column-start: 1;
      grid-column-end: 7;

      @media only screen and ${({ theme }) => theme.device.laptop} {
        grid-row-start: 4;
        grid-row-end: 8;
        grid-column-start: 3;
        grid-column-end: 9;

        &:before {
          content: "";
          position: absolute;
          width: 3px;
          height: 8vw;
          background-color: ${({ theme }) => theme.colors.golden};
          margin: 0 auto;
          top: -8vw;
          left: calc(50% - 1.5px);
        }
      }
    }
  }
`;

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query AboutPage {
    prismicSpecificPage(uid: { eq: "about" }) {
      id
      data {
        top_level_page
        title
        nav_order
        hide_in_nav
        body {
          raw
        }
      }
    }

    prismicAssets {
      id
      data {
        about_cover {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
        }
        image_2020 {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
        }
        image_1958_03 {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
        }
        image_1958_02 {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
        }
        image_1958_01 {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
        }
        image_1930_oil_01 {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
        }
        image_1930_oil_02 {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }

    prismicHomepage {
      data {
        classy_cta_image {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 3000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        classy_cta_action_text
        classy_cta_action
      }
    }
  }
`;
