/**
 * Vendor
 */
import React from "react";
import styled from "styled-components";
import { useParallax } from "react-scroll-parallax";

import Box from "./john-motion/motion-box/index";

const Parallax = styled.div`
  transition: transform 1s;
  transition-timing-function: ${({ theme }) =>
    theme.animation.timingFunction.css};
`;

const OutlineImageBoxWrap = styled(Box)`
  --height: 100%;
  --radius-top: calc(1rem + 2.5vw);
  --radius-bottom: calc(1rem + 2.5vw);
  --border: 3px;
  height: var(--height);
  background: ${({ theme }) => theme.colors.black};
  border-top-left-radius: var(--radius-top);
  border-top-right-radius: var(--radius-top);
  border-bottom-left-radius: var(--radius-bottom);
  border-bottom-right-radius: var(--radius-bottom);
  border: var(--border) solid ${({ theme }) => theme.colors.golden};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .parallax-banner {
    --radius-top-inner: calc(var(--radius-top) - var(--border));
    --radius-bottom-inner: calc(var(--radius-bottom) - var(--border));
    border-top-left-radius: var(--radius-top-inner);
    border-top-right-radius: var(--radius-top-inner);
    border-bottom-left-radius: var(--radius-bottom-inner);
    border-bottom-right-radius: var(--radius-bottom-inner);
    overflow: hidden;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  .image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  .image {
    position: absolute !important;
    top: 0;import { ThemeProvider } from 'styled-components';

    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

export default function OutlineBoxParallax({
  fluid,
  children,
  parallaxAmount = "20",
  hiddenStyles,
  controls,
  ...rest
}) {
  const parallax = useParallax({
    translateY: [-parallaxAmount, parallaxAmount],
  });

  return (
    <Parallax ref={parallax.ref} {...rest}>
      <OutlineImageBoxWrap hiddenStyles={{ x: -10, ...hiddenStyles }}>
        {children}
        {controls}
      </OutlineImageBoxWrap>
    </Parallax>
  );
}
