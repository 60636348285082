/**
 * Vendor
 */
import React from "react";
import ImageBox from "./john-motion/motion-box/image-box";
import OutlineBoxParallax from "./outline-box-parallax";

export function OutlineImageParallaxBox({
  fluid,
  children,
  hiddenStyles,
  ...rest
}) {
  return (
    <OutlineBoxParallax {...rest}>
      <ImageBox
        className="image"
        hiddenStyles={{ x: 0, y: 0, scale: 1.1, ...hiddenStyles }}
        transitionOverrides={{ duration: 3 }}
        fluid={fluid}
      />
    </OutlineBoxParallax>
  );
}
